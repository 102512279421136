var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ls-logistics"},[_c('div',{staticClass:"ls-card"},[_c('el-page-header',{attrs:{"content":"模板详情"},on:{"back":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"m-t-24"},[_c('el-form',{ref:"formRef",attrs:{"model":_vm.form,"label-width":"160px","size":"small"}},[_c('div',{staticClass:"ls-card m-t-16"},[_c('div',{staticClass:"card-content m-t-24"},[_c('el-form-item',{attrs:{"label":"模板名称","prop":"title","rules":[
                        {
                            required: true,
                            message: '请输入模板名称',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('el-input',{attrs:{"placeholder":"请输入模型名称"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"分类","prop":"category"}},[_c('el-radio-group',{on:{"change":_vm.radioChange},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},_vm._l((_vm.categoryList),function(item,index){return _c('el-radio',{key:index,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1),(_vm.form.category === 1 || _vm.form.category === 2)?_c('el-form-item',{attrs:{"label":"生产图（PSD格式）","prop":"psd_images.images","rules":[
                        {
                            required: true,
                            message: '请上传生产图',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('div',{staticStyle:{"width":"500px"}},[_c('el-upload',{staticClass:"m-t-10",attrs:{"drag":"","action":"#","show-file-list":true,"http-request":file => this.handleUpload(file, 'psd_images'),"on-error":_vm.handleError,"accept":".psd","limit":1,"file-list":_vm.psd_images,"on-remove":file => this.handleRemove(file, 'psd_images')}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)]):_vm._e(),_c('el-form-item',{attrs:{"label":"效果图（2D样机图/3D模型文件）","prop":"face_images.images","rules":[
                        {
                            required: true,
                            message: '请上传效果图',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('div',{staticStyle:{"width":"500px"}},[_c('el-upload',{staticClass:"m-t-10",attrs:{"drag":"","action":"#","show-file-list":true,"http-request":file => this.handleUpload(file, 'face_images'),"on-error":_vm.handleError,"accept":".png,.jpg","limit":9,"multiple":true,"file-list":_vm.face_images,"on-remove":file => this.handleRemove(file, 'face_images'),"on-preview":_vm.handlePreview}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)]),(_vm.form.category === 1)?_c('el-form-item',{attrs:{"label":"刀版遮罩图（中码刀版）","prop":"db_images.images","rules":[
                        {
                            required: true,
                            message: '请上传刀版遮罩图',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('div',{staticStyle:{"width":"500px"}},[_c('el-upload',{staticClass:"m-t-10",attrs:{"drag":"","action":"#","show-file-list":true,"http-request":file => this.handleUpload(file, 'db_images'),"on-error":_vm.handleError,"accept":".png,.jpg","limit":9,"multiple":true,"file-list":_vm.db_images,"on-remove":file => this.handleRemove(file, 'db_images'),"on-preview":_vm.handlePreview}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)]):_vm._e(),(_vm.form.category === 2)?_c('el-form-item',{attrs:{"label":"生产遮罩图","prop":"produce_images.images","rules":[
                        {
                            required: true,
                            message: '请上传生产遮罩图',
                            trigger: ['blur', 'change']
                        }
                    ]}},[_c('div',{staticStyle:{"width":"500px"}},[_c('el-upload',{staticClass:"m-t-10",attrs:{"drag":"","action":"#","show-file-list":true,"http-request":file => this.handleUpload(file, 'produce_images'),"on-error":_vm.handleError,"accept":".png,.jpg","limit":9,"multiple":true,"file-list":_vm.produce_images,"on-remove":file => this.handleRemove(file, 'produce_images'),"on-preview":_vm.handlePreview}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1)]):_vm._e(),_c('el-form-item',{attrs:{"label":"内容"}},[_c('editor',{model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1)])])],1),_c('el-dialog',{attrs:{"visible":_vm.uploading},on:{"update:visible":function($event){_vm.uploading=$event},"close":_vm.dialogColse}},[_c('div',{staticClass:"progress"},[_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.progressData),function(item,index){return _c('div',{key:index,staticClass:"m-b-20"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-1"},[_c('el-progress',{attrs:{"percentage":parseInt(item.percentage)}})],1)])}),0)])]),_c('div',{staticClass:"ls-logistics__footer bg-white ls-fixed-footer"},[_c('div',{staticClass:"btns row-center flex",staticStyle:{"height":"100%"}},[_c('el-button',{attrs:{"size":"small"}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1)]),(_vm.imgViewerVisible)?_c('elImageViewer',{attrs:{"url-list":_vm.imgList,"on-close":() => (_vm.imgViewerVisible = false)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }